import React from 'react';
import { Link } from 'gatsby';

import Content from '../components/content';
import Layout from '../components/layout';
import SEO from '../components/seo';

const NotFoundPage = () => (
  <Layout>
    <SEO title="Not Found" />

    <Content>
      <h1>Not Found</h1>
      <p>Sorry, can’t find that page. Try heading <Link to="/">home</Link>.</p>
    </Content>
  </Layout>
);

export default NotFoundPage;
